import axios from 'axios';
import './Css/Ratings.css';
import Swal from 'sweetalert2';
import LOGO from './img/logo_fp.png';
import HomeButton from './HomeButton';
import {useNavigate, useParams} from 'react-router-dom';
import React, {useState} from 'react';
import { useAreas } from './AreasContext'
import RatingComedor from "./RatingComedor";
import EvaluationCategoriesEnum from "../enum/EvaluationCategoriesEnum";
import areasEnum from '../enum/AreasEnum'
import {renderEmojiSet} from "../enum/EmojiUtils";

const Ratings = () => {
    useNavigate();
    const { areaId } = useParams();
    const areas = useAreas();
    const [ratings, setRatings] = useState({
        servicio: 0,
        comida: 0,
        limpieza: 0,
    });

  // Función para manejar el clic en los emojis de cada categoría
  const handleEmojiClick = async (category, selectedRating) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [category]: selectedRating,
    }));

    const data = {
        nameuser: areasEnum.USR,
        idArea: areaId,
        idEvaluationType: getEvaluationTypeId(category), // Asigna el tipo de evaluación 
        idRating: selectedRating, // Asigna la calificación seleccionada (emoji)
        feedback: "Sin comentarios"
    };

    try {
      await axios.post(`${process.env.REACT_APP_URL_BASE}/areas-evaluations/send-evaluation`, data);
      await Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: `Evaluación de ${category} enviada correctamente.`,
      });

      setRatings(prevRatings => ({
        ...prevRatings,
        [category]: 0, // Reinicia el rating para esa categoría después de enviar la evaluación
      }));
    } catch (error) {
      console.error('Error al enviar la evaluación:', error);
      await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Ocurrió un error al enviar la evaluación de ${category}.`,
      });
    }
  };

  // Asigna el ID del tipo de evaluación según la categoría
  const getEvaluationTypeId = (category) => {
    const types = {
      servicio: 1,
      comida: 2,
      limpieza: 3,
    };
    return types[category];
  };

    const areaSeleccionada = Array.isArray(areas) ? areas.find(area => area.idArea === areaId) : null;
    return (
        <div className="container">
            <img src={LOGO} alt="Logo de la empresa" className="logo" />
            <h1>Evaluación de {areaSeleccionada ? areaSeleccionada.name : 'Área No Disponible'}</h1>

            {areaSeleccionada ? (
                areaSeleccionada.idArea === areasEnum.DININGROOM ? (
                    <>
                        <RatingComedor />
                    </>
                ) : areaSeleccionada.idArea.startsWith(areasEnum.RESTROOM) ? (
                    <>
                        {renderEmojiSet(EvaluationCategoriesEnum.LIMPIEZA, ratings, handleEmojiClick)}
                    </>
                ) : (
                    renderEmojiSet(EvaluationCategoriesEnum.SERVICIO, ratings, handleEmojiClick)
                )
            ) : (
                <p>Cargando áreas...</p>
            )}
            <HomeButton />
        </div>
    );
};

export default Ratings;
