import { FaGrinBeam, FaSmile, FaMeh, FaFrown, FaAngry } from 'react-icons/fa';
import RatingsEnum from './RatingsEnum';

export const emojiList = [
    { icon: FaGrinBeam, value: RatingsEnum.EXCELLENT },
    { icon: FaSmile, value: RatingsEnum.GOOD },
    { icon: FaMeh, value: RatingsEnum.AVERAGE },
    { icon: FaFrown, value: RatingsEnum.BAD },
    { icon: FaAngry, value: RatingsEnum.VERY_BAD },
];

export const renderEmoji = (IconComponent, category, value, ratings, handleEmojiClick) => (
    <IconComponent
        className={`emoji ${ratings[category] === value ? 'selected' : ''}`}
        onClick={() => handleEmojiClick(category, value)}
    />
);

export const renderEmojiSet = (category, ratings, handleEmojiClick) => (
    <div className="emoji-container">
        <h3>Evalúa {category.charAt(0).toUpperCase() + category.slice(1)}</h3>
        {emojiList.map(({ icon: IconComponent, value }) =>
            renderEmoji(IconComponent, category, value, ratings, handleEmojiClick)
        )}
    </div>
);
