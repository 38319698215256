import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import './Css/GraphicDataEvaluations.css';

const GraphicDataEvaluations = ({ dataEvaluations, searchTerm }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (!dataEvaluations || dataEvaluations.length === 0) {
            return;
        }

        const root = am5.Root.new(chartRef.current);

        root.setThemes([am5themes_Animated.new(root)]);

        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                layout: root.verticalLayout,
            })
        );

        const xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 40,
        });

        xRenderer.labels.template.setAll({
            rotation: -45,
            centerY: am5.p50,
            centerX: 0,
            paddingRight: 15,
            fontSize: "0.9em",
        });

        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "area",
                renderer: xRenderer,
            })
        );

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );

        const series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Evaluaciones",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "area",
                tooltip: am5.Tooltip.new(root, { labelText: "{categoryX}: {valueY}" }),
            })
        );

        series.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            tooltipText: "{categoryX}: {valueY}",
        });

        series.columns.template.adapters.add("fill", (fill, target) => {
            const index = series.columns.indexOf(target);
            const colors = ["#2C3E50", "#013971", "#5F5F5F"];
            return am5.color(colors[index % colors.length]);
        });

        series.columns.template.adapters.add("stroke", (stroke, target) => {
            const index = series.columns.indexOf(target);
            const colors = ["#2C3E50", "#013971", "#5F5F5F"];
            return am5.color(colors[index % colors.length]);
        });

        // Filtrar datos según el término de búsqueda
        const filteredData = dataEvaluations.filter((item) =>
            item.area.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const aggregatedData = filteredData.reduce((acc, evaluation) => {
            const { area, calificacion } = evaluation;

            if (!acc[area]) {
                acc[area] = { area, value: 0 };
            }
            acc[area].value += calificacion;
            return acc;
        }, {});

        const chartData = Object.values(aggregatedData);

        xAxis.data.setAll(chartData);
        series.data.setAll(chartData);

        series.appear(1000);
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [dataEvaluations, searchTerm]); // Escuchar cambios en el término de búsqueda

    return (
        <div>
            <h2 className="text">Gráfica de Evaluaciones</h2>
            {dataEvaluations && dataEvaluations.length > 0 ? (
                <div id="chartdiv" ref={chartRef} style={{ width: "100%", height: "500px" }}></div>
            ) : (
                <p>No hay datos para mostrar en la gráfica.</p>
            )}
        </div>
    );
};

export default GraphicDataEvaluations;
