import React, { useState, useEffect } from "react";
import axios from "axios";
import './Css/UsersData.css';
import swal from 'sweetalert2';
import { MdDelete } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";

function UsersData({ refresh }) {
    const [usuarios, setUsuarios] = useState([]);
    const token = localStorage.getItem("authToken");

    const fetchUsuarios = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_BASE}/user/get-data`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.status) {
                setUsuarios(response.data.data);
            }
        } catch (error) {
            console.error("Error al obtener los usuarios:", error);
        }
    };

    const handleEdit = async (user) => {
        const { value: formValues } = await swal.fire({
            title: `<FaUserEdit /> Editar usuario`,
            html: `
                <style>
                    .swal2-popup .swal2-input { 
                        font-size: 1.2rem;
                        width: 80%;
                        margin-bottom: 10px;
                    }
                    .swal2-popup .form-label {
                        font-size: 14px;
                        display: inline-block;
                        margin-bottom: 5px;
                        width: 80px;
                    }
                    .swal2-popup .input-row {
                        display: flex;
                        align-items: center;
                        margin-top: 15px;
                        gap: 10px;
                    }
                </style>
                <div>
                    <label class="form-label">Usuario</label>
                    <input id="swal-username" class="swal2-input" value="${user.username}">
                </div>
                <div>
                    <label class="form-label">Contraseña</label>
                    <input id="swal-password" class="swal2-input" type="password" placeholder="Nueva contraseña (opcional)">
                </div>
                <div>
                    <label class="form-label">Email</label>
                    <input id="swal-email" class="swal2-input" type="email" value="${user.email}">
                </div>
                <div class="input-row">
                    <label class="form-label">Rol</label>
                    <select id="swal-role" class="swal2-input" style="width: 60%">
                        <option value="2" ${user.roleId === 2 ? 'selected' : ''}>Usuario</option>
                        <option value="1" ${user.roleId === 1 ? 'selected' : ''}>Admin</option>
                    </select>
                </div>
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {
                return {
                    username: document.getElementById('swal-username').value,
                    password: document.getElementById('swal-password').value || null,
                    email: document.getElementById('swal-email').value,
                    roleId: Number(document.getElementById('swal-role').value)
                };
            }
        });

        if (formValues) {
            try {
                await axios.put(`${process.env.REACT_APP_URL_BASE}/user/update/${user.id}`, formValues, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    }
                });

                await swal.fire('Actualizado', 'El usuario ha sido actualizado correctamente.', 'success');
                await fetchUsuarios();
            } catch (error) {
                console.error("Error al actualizar el usuario:", error);
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            const result = await swal.fire({
                title: '¿Estás seguro?',
                text: "Esta acción no se puede deshacer",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                await axios.delete(`${process.env.REACT_APP_URL_BASE}/user/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                await swal.fire('Eliminado', `Usuario con ID ${id} eliminado correctamente.`, 'success');
                await fetchUsuarios();
            }
        } catch (error) {
            console.error("Error al eliminar el usuario:", error);
        }
    };

    useEffect(() => {
        fetchUsuarios().then(r => refresh);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh, token]);

    return (
        <div>
            <div className="usuarios-container">
                <table className="usuarios-table">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nombre de Usuario</th>
                        <th>Email</th>
                        <th>Fecha de Creación</th>
                        <th>Fecha de Actualización</th>
                        <th>Rol</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {usuarios.map(usuario => (
                        <tr key={usuario.id}>
                            <td>{usuario.id}</td>
                            <td>{usuario.username}</td>
                            <td>{usuario.email}</td>
                            <td>{new Date(usuario.createdAt).toLocaleString()}</td>
                            <td>{new Date(usuario.updatedAt).toLocaleString()}</td>
                            <td>{usuario.roleId === "1" ? "Admin" : "User"}</td>
                            {usuario.id === "1" || usuario.username === localStorage.getItem("username") ?
                                <td></td> :
                                <td>
                                    <FaUserEdit className="edit-button" onClick={() => handleEdit(usuario)} />
                                    <MdDelete className="delete-button" onClick={() => handleDelete(usuario.id)} />
                                </td>
                            }
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default UsersData;
