import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import EvaluationCategoriesEnum from "../enum/EvaluationCategoriesEnum";
import AreasEnum from '../enum/AreasEnum';
import { renderEmojiSet } from "../enum/EmojiUtils";

const RatingComedor = () => {
    const { areaId } = useParams();

    const [ratings, setRatings] = useState({
        servicio: 0,
        comida: 0,
        limpieza: 0,
    });

    const getEvaluationTypeId = (category) => {
        const types = {
            servicio: 1,
            comida: 2,
            limpieza: 3,
        };
        return types[category];
    };

    const categories = [
        EvaluationCategoriesEnum.SERVICIO,
        EvaluationCategoriesEnum.COMIDA,
        EvaluationCategoriesEnum.LIMPIEZA,
    ];

    const allRatingsSelected = Object.values(ratings).every((rating) => rating > 0);

    const handleEmojiClick = (category, selectedRating) => {
        setRatings((prevRatings) => ({
            ...prevRatings,
            [category]: prevRatings[category] === selectedRating ? 0 : selectedRating,
        }));
    };

    useEffect(() => {
        if (!allRatingsSelected) return;

        const data = Object.keys(ratings).map((category) => ({
            nameuser: AreasEnum.USR,
            idArea: areaId,
            idEvaluationType: getEvaluationTypeId(category),
            idRating: ratings[category],
            feedback: "Sin comentarios",
        }));

        axios.post(`${process.env.REACT_APP_URL_BASE}/areas-evaluations/send-evaluations-cmd`, data)
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: 'Evaluaciones enviadas correctamente.',
                });
                setRatings({
                    servicio: 0,
                    comida: 0,
                    limpieza: 0,
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Ocurrió un error al enviar las evaluaciones.',
                });
            });
    }, [allRatingsSelected, ratings, areaId]);

    return (
        <div className="ratings-comedor">
            {categories.map((category) => (
                <div key={category}>
                    {renderEmojiSet(category, ratings, handleEmojiClick)}
                </div>
            ))}
        </div>
    );
};

export default RatingComedor;