import { FaAngry, FaFrown, FaGrinBeam, FaMeh, FaSmile } from "react-icons/fa";
import './Css/RatingsCount.css';
import { useEffect, useState } from "react";
import axios from "axios";
import { useAreas } from './AreasContext';

function RatingsCount({ refresh }) {
    const token = localStorage.getItem('authToken');
    const [ratingsCount, setRatingsCount] = useState([]);
    const [selectedArea, setSelectedArea] = useState(""); // Área seleccionada
    const areas = useAreas(); // Accede a las áreas desde el contexto

    // Fetch inicial y cuando cambia el área seleccionada
    useEffect(() => {
        const fetchCount = async () => {
            try {
                const url = selectedArea
                    ? `${process.env.REACT_APP_URL_BASE}/areas-evaluations/get-ratings-count-by-id?areaId=${selectedArea}`
                    : `${process.env.REACT_APP_URL_BASE}/areas-evaluations/get-ratings-count`;

                const response = await axios.post(
                    url,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.data.status) {
                    setRatingsCount(response.data.data);
                }
            } catch (error) {
                console.error("Error al obtener ratings:", error);
            }
        };

        fetchCount();
    }, [refresh, token, selectedArea]);

    ratingsCount.reduce((accumulator, value) => {
        const total =
            (value.excelent || 0) +
            (value.good || 0) +
            (value.regular || 0) +
            (value.bad || 0) +
            (value.veryBad || 0);
        return accumulator + total;
    }, 0);
    return (
        <div className="ratings-count-container">
            <div className="header">
                <h2 className="totales">
                    {selectedArea ? `Calificaciones Totales para ${selectedArea}` : "Calificaciones Totales"}
                </h2>
                <div className="area-select-container">
                    <label htmlFor="area-select">Área:</label>
                    <select
                        id="area-select"
                        value={selectedArea}
                        onChange={(e) => setSelectedArea(e.target.value)}
                        className="custom-select"
                    >
                        <option value="">Todas las áreas</option>
                        {areas.length > 0 &&
                            areas.map((area) => (
                                <option key={area.idArea} value={area.idArea}>
                                    {area.name}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
            {ratingsCount.length > 0 ? (
                selectedArea ? (
                    // Vista por atributos (cuando se selecciona un área)
                    <div className="ratings-rows">
                        {ratingsCount.map((rating, index) => (
                            <div className="ratings-row" key={index}>
                                <h4 className="attribute-name">{rating.atribute || "Atributo no disponible"}</h4>
                                <div className="emojis-container">
                                    <div className="card">
                                        <div className="icon">
                                            <FaGrinBeam className="emoji-icon grin" />
                                        </div>
                                        <p>Excelente: {rating.excelent || 0}</p>
                                    </div>
                                    <div className="card">
                                        <div className="icon">
                                            <FaSmile className="emoji-icon smile" />
                                        </div>
                                        <p>Bueno: {rating.good || 0}</p>
                                    </div>
                                    <div className="card">
                                        <div className="icon">
                                            <FaMeh className="emoji-icon meh" />
                                        </div>
                                        <p>Regular: {rating.regular || 0}</p>
                                    </div>
                                    <div className="card">
                                        <div className="icon">
                                            <FaFrown className="emoji-icon frown" />
                                        </div>
                                        <p>Malo: {rating.bad || 0}</p>
                                    </div>
                                    <div className="card">
                                        <div className="icon">
                                            <FaAngry className="emoji-icon angry" />
                                        </div>
                                        <p>Muy Malo: {rating.veryBad || 0}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    // Vista agregada (sin área seleccionada)
                    <div className="ratings-row">
                        <h4 className="attribute-name">Totales</h4>
                        <div className="emojis-container">
                            <div className="card">
                                <div className="icon">
                                    <FaGrinBeam className="emoji-icon grin" />
                                </div>
                                <p>Excelente: {ratingsCount[0]?.count || 0}</p>
                            </div>
                            <div className="card">
                                <div className="icon">
                                    <FaSmile className="emoji-icon smile" />
                                </div>
                                <p>Bueno: {ratingsCount[1]?.count || 0}</p>
                            </div>
                            <div className="card">
                                <div className="icon">
                                    <FaMeh className="emoji-icon meh" />
                                </div>
                                <p>Regular: {ratingsCount[2]?.count || 0}</p>
                            </div>
                            <div className="card">
                                <div className="icon">
                                    <FaFrown className="emoji-icon frown" />
                                </div>
                                <p>Malo: {ratingsCount[3]?.count || 0}</p>
                            </div>
                            <div className="card">
                                <div className="icon">
                                    <FaAngry className="emoji-icon angry" />
                                </div>
                                <p>Muy Malo: {ratingsCount[4]?.count || 0}</p>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <p className="no-data-message">No hay datos disponibles.</p>
            )}
        </div>
    );


}

export default RatingsCount;
